import { ValidationService } from './../../../../../core/services/ValidationForms.service';
import { TournamentBody } from './../models/tournamentBody.model';
import { System } from './../../../../OnsiteModules/ReportsModule/models/System.model';
import { TournamentProduct } from './../models/tournamentProduct.model';
import { TournamentsService } from './../services/tournaments.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Location } from './../../../../../core/models/Location.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Tournament } from '../models';
import { TournamentTemplate } from '../../../TournamentSiteModule/TournamentTemplatesModule/models';

@Component({
  selector: 'app-tournaments-create',
  templateUrl: './tournaments-create.component.html',
  styleUrls: ['./tournaments-create.component.scss']
})
export class TournamentsCreateComponent implements OnInit {
  public coverMessage = false;
  public bannerMessage = false;
  public loading = false;
  public tournamentBody: FormGroup;
  public formArray: FormArray;
  public validForm = false;

  public tournamentDate: Date = new Date();
  public limitDate: Date = new Date();
  public todayDate: Date = new Date();
  public templateId: number;
  public systemId: number;
  public minTeams = 2;
  private _locationId: number;
  public get locationId(): number {
    return this._locationId;
  }
  public set locationId(value: number) {
    this._locationId = value;
    this.systems = [];
  }

  private _isMaxTeamsUnlimited: boolean;
  public get isMaxTeamsUnlimited(): boolean {
    return this._isMaxTeamsUnlimited;
  }
  public set isMaxTeamsUnlimited(value: boolean) {
    this._isMaxTeamsUnlimited = value;
    if (value) {
      this.formArray.at(1).get('maxTeams').patchValue(-1);
      this.formArray.at(1).get('maxTeams').clearValidators();
      this.formArray.at(1).get('maxTeams').updateValueAndValidity();
    }
  }

  public scheduledAt: Date = new Date();

  private _scheduledHour: Date;
  public get scheduledHour(): Date {
    return this._scheduledHour;
  }
  public set scheduledHour(value: Date) {
    this.scheduledAt.setHours(value.getHours());
    this.scheduledAt.setMinutes(value.getMinutes());
    this._scheduledHour = value;
  }

  public imageLoaded: any = {
    cover: { loaded: false, file: '' },
    banner: { loaded: false, file: '' }
  };

  public fileLoaded: any = {
    rules: { loaded: false, file: '' },
    setup: { loaded: false, file: '' }
  };

  private _tournamentProducts = [];
  public get tournamentProducts(): TournamentProduct[] {
    return this._tournamentProducts;
  }
  public set tournamentProducts(value: TournamentProduct[]) {
    this.tournamentsService
      .getTournamentProducts(this.formArray.at(0).get('locationId').value)
      .subscribe(data => (this._tournamentProducts = data));
  }

  private _systems: System[];
  public get systems(): System[] {
    return (this.selectedLocation) ? this._systems : [];
  }
  public set systems(systems: System[]) {
    if (this.selectedLocation) {
      this.tournamentsService.getSystems(this.selectedLocation.domain).subscribe((data: System[]) => {
        this._systems = data ? data : [];
      });
    }
  }

  public get locations(): Location[] {
    return this.data.locations;
  }

  public get selectedLocation(): Location {
    return this.locations.find(template => template.id === this.locationId);
  }

  public get tournamentTemplates(): TournamentTemplate[] {
    return this.data.tournamentTemplates;
  }

  public get selectedTemplate(): TournamentTemplate {
    return this.tournamentTemplates.find(template => template.id === this.formArray.at(0).get('templateId').value);
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TournamentsCreateComponent>,
    private tournamentsService: TournamentsService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.formArray = new FormArray([
      new FormGroup({
        locationId: new FormControl(this.locationId, [Validators.required]),
        templateId: new FormControl(this.templateId, [Validators.required]),
        isAvailable: new FormControl(false, [])
      }),
      new FormGroup({
        tournamentProductId: new FormControl('', [Validators.required]),
        name: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(120)
        ]),
        scheduledAt: new FormControl(this.scheduledAt, [Validators.required]),
        minTeams: new FormControl(this.minTeams, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(3),
          ValidationService.minValue(2),
          ValidationService.integerValidator()
        ]),
        maxTeams: new FormControl('', [
          Validators.minLength(1),
          Validators.maxLength(3),
          ValidationService.minValue(2),
          ValidationService.higherThan(this.minTeams),
          ValidationService.integerValidator()
        ]),
        systemId: new FormControl(this.systemId, [Validators.required]),
        systemName: new FormControl('')
      }),
      new FormGroup({
        cover: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(51200000)
        ]),
        banner: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(51200000)
        ]),
        rules: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(1000000000)
        ]),
        setup: new FormControl('', [
          Validators.required,
          ValidationService.imageSizeValidator(1000000000)
        ])
      })
    ]);
    this.tournamentBody = new FormGroup({ formArray: this.formArray });
  }

  public ngOnInit() {
    this.systems = [];
    this.tournamentProducts = [];
    this.limitDate.setMonth(12);
  }
  public createTournament(): void {
    if(this.tournamentBody.valid){
      const tournament: TournamentBody = Object.assign(this.formArray.value[0], this.formArray.value[1], this.formArray.value[2]);
      tournament.systemName = this.systems.find(system => system.id === this.systemId).name;
      this.loading = !this.loading;
      this.tournamentsService.postTournament(tournament).subscribe(
        (data: Tournament) => {
          this.loading = !this.loading;
          this.dialogRef.close(data);
        },
        (error: Error) => {
          this.loading = !this.loading;
        }
      );
    } else
    this.validForm = true;
  }

  public handleImageLoad(property: string): void {
    this.imageLoaded[property].loaded = true;
  }

  public handleInputChange(propertyName: string, e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.formArray
      .at(2)
      .get(propertyName)
      .patchValue(e.target.files[0]);

    const pattern = /image-*/;
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this, propertyName);
    reader.readAsDataURL(file);
  }

  public handleInputFileChange(propertyName: string, e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.formArray
      .at(2)
      .get(propertyName)
      .patchValue(e.target.files[0]);

    const reader = new FileReader();
    reader.onload = this._handleInputFileReaderLoaded.bind(this, propertyName);
    reader.readAsDataURL(file);
  }

  public _handleReaderLoaded(property, e): void {
    const reader = e.target;
    this.imageLoaded[property].file = reader.result;
    if(property === 'cover' || property === 'banner'){
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        if(property === 'cover'){
          if(image.width === 1060 && image.height === 520){
            this.coverMessage = false;
          } else {
            this.coverMessage = true;
          }
        } else if (property === 'banner') {
          if(image.width === 330 && image.height === 380){
            this.bannerMessage = false;
          } else {
            this.bannerMessage = true;
          }
        }
      }
    }
  }

  public _handleInputFileReaderLoaded(property, e): void {
    const reader = e.target;
    this.fileLoaded[property].file = reader.result;
  }
}
